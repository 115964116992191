import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Item, View, ProgressCircle, Link, ComboBox, TagGroup } from '@adobe/react-spectrum';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListUserRequest } from '../../../../../services/soap/access/requests/ListUserRequest';
import { useTranslation } from 'react-i18next';
import styles from './users_filter_component.module.css';

interface UsersFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
}

const UsersFilterComponent: React.FC<UsersFilterComponentProps> = ({ createParams, filterParams }) => {
  const { t } = useTranslation();
  const { store, accessService } = useDependency();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ userList: true });
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [availableUsers, setAvailableUsers] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const refSelectedUsers = useRef<any>([]);

  const setPreSelectedValue = useCallback((filterAvailableUsers: any[], selectedKey: any) => {
    const selectedUser = filterAvailableUsers.find((user: any) => user.id === selectedKey);
    if (selectedUser) {
      refSelectedUsers.current = [...refSelectedUsers.current, selectedUser];
      setSelectedUsers((preValue: any) => [...preValue, selectedUser]);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setShowLoader(true);
    try {
      const filterAvailableUsers = JSON.parse(localStorage.getItem('filterAvailableUsers') || '[]');
      //console.log('filterAvailableUsers', filterAvailableUsers);
      if (filterAvailableUsers.length > 0) {
        const userExists = filterParams?.FILTER.COL.find((col: any) => col.name === 'responsibleUser');
        if (userExists) {
          const arrUserExists = userExists.value.split(',');
          arrUserExists.forEach((userId: any) => {
            setPreSelectedValue(filterAvailableUsers, userId);
          });
          arrUserExists.forEach((userId: any) => {
            setAvailableUsers(filterAvailableUsers.filter((user: any) => user.id !== userId));
          });
        } else {
          setAvailableUsers(filterAvailableUsers);
        }
      } else {
        const totalRecords: any = await getUserCount();
        await getUserData(totalRecords);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setShowLoader(false);
    }
  }, [filterParams, setPreSelectedValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getUserCount = useCallback(async () => {
    let userList = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, undefined, undefined, '1'));
    if (userList.result === 'OK') {
      return userList.count;
    }
  }, []);

  const getUserData = useCallback(async (totalRecords: number) => {
    let userResp = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, totalRecords));

    if (userResp.result === 'OK') {
      //console.log('userResp.USERS', userResp.USERS);
      const formattedArray = userResp.USERS.map(({ USER }) => ({
        id: String(USER.id),
        name: `${USER.name1} ${USER.name3}`,
      }));
      //formattedArray.unshift({ id: 'All users', name: 'All users' });
      //console.log('formattedArray', formattedArray);
      localStorage.setItem('filterAvailableUsers', JSON.stringify(formattedArray));
      setAvailableUsers(formattedArray);
    }
  }, []);

  const handleSelectionChange = (selectedKey: any) => {
    const selectedUser = availableUsers.find((user: any) => user.id === selectedKey);
    if (selectedUser) {
      refSelectedUsers.current = [...refSelectedUsers.current, selectedUser];
      setSelectedUsers((preValue: any) => [...preValue, selectedUser]);
      setAvailableUsers(availableUsers.filter((user: any) => user.id !== selectedKey));
      const updateValue = refSelectedUsers.current.length > 0 ? new Set(refSelectedUsers.current.map((item: any) => item.id)) : new Set(['All users']);
      createParams('user', updateValue);
    }
  };

  const handleRemoveUser = (value: any) => {
    const arrValue = Array.from(value);
    //console.log('arrValue', arrValue[0]);
    let userId = arrValue[0];
    const removedUser = selectedUsers.find((user: any) => user.id === userId);
    if (removedUser) {
      refSelectedUsers.current = refSelectedUsers.current.filter((user: any) => user.id !== userId);
      setSelectedUsers(selectedUsers.filter((user: any) => user.id !== userId));
      setAvailableUsers([...availableUsers, removedUser]);
      const updateValue = refSelectedUsers.current.length > 0 ? new Set(refSelectedUsers.current.map((item: any) => item.id)) : new Set(['All users']);
      createParams('user', updateValue);
    }
  };

  const handleRemoveAllUsers = () => {
    refSelectedUsers.current = [];
    setAvailableUsers([...availableUsers, ...selectedUsers]);
    setSelectedUsers([]);
    createParams('user', new Set(['All users']));
  };

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.users_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.users_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.users_heading_text}>
                {t('user', { ns: 'layout_components' })}
              </View>
            </Flex>
            {toggleListVisibility.userList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, userList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, userList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            marginStart={'8px'}
            UNSAFE_className={styles.users_component}
            UNSAFE_style={{ display: toggleListVisibility.userList ? '' : 'none' }}
          >
            <ComboBox selectedKey={null} defaultItems={availableUsers} onSelectionChange={handleSelectionChange} aria-label="none">
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ComboBox>
            {selectedUsers.length > 0 && (
              <>
                <TagGroup id="tagGroupUsers" aria-label="Selected Users" items={selectedUsers} onRemove={key => handleRemoveUser(key)}>
                  {(item: any) => (
                    <Item key={item.id}>
                      <img src="../../../../../../assets/images/avatar--desktop--light@2x.png" alt="" style={{ width: '20px', marginRight: '5px' }} />
                      {item.name}
                    </Item>
                  )}
                </TagGroup>{' '}
                <View position={'relative'} width={'100%'} height={'30px'} UNSAFE_className={styles.users_clear_all_container}>
                  <View position={'relative'} UNSAFE_className={styles.users_clear_all_text}>
                    {selectedUsers.length > 0 && (
                      <Link
                        onPress={() => {
                          handleRemoveAllUsers();
                        }}
                      >
                        {t('reset_all', { ns: 'layout_components' })}
                      </Link>
                    )}
                  </View>
                </View>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default UsersFilterComponent;
